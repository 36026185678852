<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <iframe src="https://dirsheregesh.ru/cameras" scrolling="auto"> </iframe>
</template>

<style scoped>
iframe {
  width: 97%;
  height: 70vh;
}
</style>
