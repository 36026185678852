<script>
export default {
  data() {},
  methods: {},
  mounted() {},
};
</script>

<template>
  <div class="wrapper-for-text">
    <h2>Политика подтверждения номера телефона</h2>
    <h4>Цель политики</h4>
    <div class="text">
      Целью данной политики является обеспечение безопасности и защиты
      персональных данных пользователей на сайте СНЕГ-ИНФО.
    </div>
    <h4>Подтверждение номера телефона</h4>
    <div class="text">
      Для подтверждения номера телефона пользователи должны ввести последние 4
      цифры своего номера телефона. Эти цифры будут отправлены на номер телефона
      пользователя через звонок. Пользователь должен принять звонок и ввести
      полученный код подтверждения на сайте.
    </div>
    <h4>Использование данных</h4>
    <div class="text">
      Данные, полученные в результате подтверждения номера телефона,
      используются исключительно для подтверждения номера телефона пользователя.
      Они не передаются третьим сторонам и не используются для маркетинговых или
      рекламных целей.
    </div>
    <h4>Связь с пользователем</h4>
    <div class="text">
      В случае возникновения вопросов или проблем с подтверждением номера
      телефона, пользователи могут связаться с нашей службой поддержки по
      электронной почте [email protected] Пользователи, которые не желают
      подтверждать свой номер телефона, могут продолжить использовать сайт без
      подтверждения номера телефона. Однако, некоторые функции сайта могут быть
      ограничены. Пользователи, которые отказываются от подтверждения номера
      телефона, соглашаются с тем, что они не получат уведомлений о важных
      событиях или обновлениях, связанных с их аккаунтом. Пользователи, которые
      подтверждают свой номер телефона, соглашаются с тем, что они получат
      уведомления о важных событиях или обновлениях, связанных с их аккаунтом,
      на их номер телефона. Эта политика действует с момента ее публикации на
      сайте СНЕГ-ИНФО. Мы резервируем право вносить изменения в эту политику в
      любое время. Пожалуйста, регулярно проверяйте эту страницу на предмет
      обновлений
    </div>
  </div>
</template>

<style scoped>
.wrapper-for-text {
  width: 95%;
  height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.text,
h2,
h4 {
  color: #d5d5d5;
  width: 100%;
  margin-bottom: 15px;
}

h2,
h4 {
  text-align: center;
  color: #fff;
}
</style>
